<template>
  <div class="work-page zerafa">

    <div class="container-block">
    	<!-- Header -->
    	<section class="header">
        <img src="/static/images/ZF_1.png" class="mobile-fullwidth"/>
    		<div class="meta">
    			<p class="client">MICHAEL ZERAFA</p>
    			<a class="project">FAITH</a>
    		</div>
    		<div class="copy container">
    			<div class="col-1">
    				<h1>Boxing is a test of faith</h1>
    			</div>
    			<div class="col-2">
    				<p>In the ring, you are alone. It’s just you and the man who wants to kill you. If you want to win, you need faith like concrete, you need faith like Zerafa.</p>
    			</div>
    		</div>
    	</section>

    </div>

    <div class="spacer-lg mobile-only"></div>

    <div style="padding:56.25% 0 0 0; position:relative;" class="mobile-fullwidth">
      <!-- <div 
      id="poster-image"
      class="poster-image poster-image-1"
      style="background: url('/static/images/SN_placeholder.png') center center; background-size: cover"
      @click="playVideo(1)"
      ></div> -->
      <iframe 
        id="video-1"
        class="video"
        src="https://player.vimeo.com/video/418868310?title=0&byline=0&portrait=0" 
        style="position:absolute;top:0;left:0;width:100%;height:100%;" 
        frameborder="0" 
        allow="fullscreen" 
        allowfullscreen>
      </iframe>
    </div>

    <div class="spacer-lg mobile-only"></div>

    <div class="container-block">

      <div class="standout-copy" style="text-align: left; width: 100%;">
        “It's a crazy thing, but I truly believe I was born for this, born for greatness.” 
      </div>

      <div class="spacer-lg"></div>

      <img src="/static/images/ZF_2.png" class="desktop-only" style="width:90%"/>

      <img src="/static/images/ZF_2.png" class="mobile-only mobile-fullwidth" style="margin-top: 3%"/>

      <img src="/static/images/ZF_3.png" class="desktop-only" style="width:40%; float: right; position: relative; z-index: 3; margin-top: -15%;"/>

      <div class="clear"></div>

      <div class="two-col desktop-only" style="justify-content: space-between; align-items: flex-start; margin-top: 20%">
        <div class="col desktop-only" style="width: 45%; position: relative; z-index: 2;">
          <img src="/static/images/ZF_4.png" style="width: 100%; margin-right: 10%; margin-top: -110%;"/>
        </div>

      </div>

      <div class="spacer-lg desktop-only"></div>

      <div class="mobile-only" style="margin-top: -10%">
        <img src="/static/images/ZF_3.png"/>
        <div class="spacer-lg"></div>
        <img src="/static/images/ZF_4.png"/>
        <div class="spacer-lg"></div>
      </div>

      <div class="standout-copy zarafa-standout" style="text-align: left;">
        “He’s just a man, he’s got two arms and a head, cut him and he bleeds red.”
      </div>

      <div class="spacer-lg"></div>

      <img src="/static/images/ZF_5.png" class="mobile-fullwidth"/>

      <div class="footer">
        <div class="prev">
          <router-link to="/western-bulldogs">
            <img src="/static/images/prev.svg"/>
          </router-link>
        </div>
        <div class="footer-contact">
          <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
          <span>&copy; {{date}} by Blood UTD</span>
        </div>
        <div class="next">
          <router-link to="/netball-victoria">
            <img src="/static/images/next.svg"/>
          </router-link>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'super-netball',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  },
  methods: {
    playVideo(id) {
      $(".poster-image-" + id).hide();
      var symbol = $("#video-" + id)[0].src.indexOf("?") > -1 ? "&" : "?";
      //modify source to autoplay and start video
      $("#video-" + id)[0].src += symbol + "autoplay=1";
    }
  },
  mounted() {
    // $(".video").hover(function(event) {
    //     if(event.type === "mouseenter") {
    //         $(this).attr("controls", "");
    //     } else if(event.type === "mouseleave") {
    //         $(this).removeAttr("controls");
    //     }
    // });
    // $('.video').click(function(el) {
    //     this.paused ? this.play() : this.pause();
    // });

    // document.getElementById("poster-image").onclick = function() { 
    //     $(".poster-image").hide();
    //     $(".video")[0].src += "&autoplay=1";
    // };
    // document.getElementById("poster-image-2").onclick = function() { 
    //     $(".poster-image-2").hide();
    //     // $("#video-2")[0].src += "&autoplay=1";
    //     var symbol = $("#video-2")[0].src.indexOf("?") > -1 ? "&" : "?";
    //     //modify source to autoplay and start video
    //     $("#video-2")[0].src += symbol + "autoplay=1";
    // };
    // document.getElementById("poster-image-3").onclick = function() { 
    //     $(".poster-image-3").hide();
    //     $("#video-3")[0].src += "&autoplay=1";
    // };
    // document.getElementById("poster-image-4").onclick = function() { 
    //     $(".poster-image-4").hide();
    //     $("#video-4")[0].src += "&autoplay=1";
    // };

  }
}
</script>

<style lang="scss" scoped>

.zerafa {

  .poster {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      z-index: 3;
      cursor: pointer;
  }

  .zarafa-standout {
    width: 85%;

    @media(max-width: 1000px) {
      width: 100%;
    }
  }

}

</style>
